<template>
  <div>
    <el-row >
        <el-row class="mt10_ml100">
          <el-col :span="12">
            <el-card class="width500">
              <el-form label-width="auto" size="small" style="margin-top: 10px">
                <el-upload
                    class="avatar-uploader"
                    :action="'http://' + serverIp + ':8080/file/upload'"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                >
                  <img v-if="form.avatarUrl" :src="form.avatarUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>

                <el-form-item label="Username">
                  <el-input v-model="form.username" disabled autocomplete="off" class="width350"></el-input>
                </el-form-item>
                <el-form-item label="Nickname">
                  <el-input v-model="form.nickname" autocomplete="off" class="width350"></el-input>
                </el-form-item>
                <el-form-item label="Email">
                  <el-input v-model="form.email" autocomplete="off" class="width350"></el-input>
                </el-form-item>
                <el-form-item label="Phone">
                  <el-input v-model="form.phone" autocomplete="off" class="width350"></el-input>
                </el-form-item>
                <el-form-item label="Address">
                  <el-input type="textarea" v-model="form.address" autocomplete="off"  class="width350"></el-input>
                </el-form-item>
                <div style="text-align: center">
                  <el-button type="primary" @click="save" >S A V E</el-button>
                </div>
              </el-form>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card class="width500">
              <div  style="text-align: center; margin-top: 10px">
                <h1>Change Your Password</h1>
              </div>
              <el-form label-width="auto" size="small" :model="form" :rules="rules" ref="pass" style="margin-top: 20px; margin-bottom: 20px">
                <el-form-item label="Old passwords" prop="password">
                  <el-input  v-model="form.password" autocomplete="off" show-password  class="width350"></el-input>
                </el-form-item>
                <el-form-item label="New passwords" prop="password">
                  <el-input v-model="form.newPassword" autocomplete="off" show-password  class="width350"></el-input>
                </el-form-item>
                <el-form-item label="Confirm Password" prop="password">
                  <el-input v-model="form.confirmPassword" autocomplete="off" show-password  class="width350"></el-input>
                </el-form-item>
                <div style="text-align: center">
                  <el-button type="primary" @click="savePassword" >S A V E</el-button>
                </div>
              </el-form>
            </el-card>
          </el-col>
          <el-col :span="24">
              <el-card class="width500_mt10">
                  <div  style="text-align: center; margin-top: 10px">
                      <h1>Real-name Information</h1>
                  </div>
                  <el-form label-width="auto" size="small" :model="form" :rules="rules1" ref="pass" style="margin-top: 20px; margin-bottom: 20px">
                      <el-form-item label="Name" prop="trueName">
                          <el-input  v-model="form.trueName" autocomplete="off" class="width300"></el-input>
                      </el-form-item>
                      <el-form-item label="ID Number" prop="trueId">
                          <el-input v-model="form.trueId" autocomplete="off" class="width300"></el-input>
                      </el-form-item>
                      <div style="text-align: center">
                          <el-button type="primary" @click="save" >确 定</el-button>
                      </div>
                  </el-form>
              </el-card>
          </el-col>
        </el-row>
    </el-row>
  </div>
</template>

<script>
import {serverIp} from "../../../public/config";

export default {
  name: "FrontPerson",
  data() {
    return {
      serverIp: serverIp,
      form: {},
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      rules:{
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
      },
      rules1:{
          trueName: [
              { required: true, message: '请输入真实姓名', trigger: 'blur' },
          ],
          trueId: [
              { required: true, message: '请输入身份证号', trigger: 'blur' },
              { min: 18, max: 18, message: '请输入18位身份证号', trigger: 'blur' }
          ],
      },
    }
  },
  created() {
    this.getUser().then(res => {
      this.form = res
    })
    this.form.username = this.user.username
  },
  methods: {
    async getUser() {
      return (await this.request.get("/user/username/" + this.user.username)).data
    },
    save() {
      this.request.post("/user", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功,请重新登录同步数据")
          this.logout()
          //触发父级更新User的方法
          this.$emit("refreshUser")
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    logout(){
        this.$store.commit("logout")
        location.reload()
    },
    handleAvatarSuccess(res) {
      this.form.avatarUrl = res
    },
    savePassword(){
      this.$refs.pass.validate((vaild) => {
        if (vaild) {
          if(this.form.newPassword !== this.form.confirmPassword){
            this.$message.error("两次密码输入不一致！")
            return false
          }
          this.request.post("/user/password", this.form).then(res => {
            if (res.code === '200') {
              this.$message.success("保存成功,请重新登录同步数据")
              this.logout()
              //this.$store.commit("logout")
            }else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style>
.avatar-uploader {
  text-align: center;
  padding-bottom: 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 138px;
  height: 138px;
  line-height: 138px;
  text-align: center;
}
.avatar {
  width: 138px;
  height: 138px;
  display: block;
}
.mt10_ml100 {
  margin-top:10px; 
  margin-left: 100px;
}
.width300 {
  width: 300px;
}
.width350 {
  width: 300px;
}
.width500 {
  width: 500px;
}
.width500_mt10 {
  width: 500px;
  margin-top: 10px;
}
</style>
